import cx from "classnames";
import PropTypes from "prop-types";

const CoreContainer = ({
  id,
  mRef,
  theme,
  children,
  className,
  containerClassName = "",
  isFullWidth = false,
  mobilePadding = true,
}) => (
  <div id={id} ref={mRef} className={cx("core-container", theme ? `theme theme--${theme}` : null, containerClassName)}>
    <div
      className={cx(
        "mx-auto w-full",
        {
          ["mbpadding"]: !!mobilePadding,
          ["z-1 relative px-6 sm:px-10 md:max-w-[1280px] md:px-20"]: !isFullWidth,
        },
        className
      )}
    >
      {children}
    </div>
  </div>
);

CoreContainer.propTypes = {
  id: PropTypes.string,
  mRef: PropTypes.node,
  theme: PropTypes.oneOf(["light", "soft", "dark", "new", "softgradient"]),
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  isFullWidth: PropTypes.bool,
  mobilePadding: PropTypes.bool,
};

export default CoreContainer;
