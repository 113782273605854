import React, { useRef, useEffect, useState } from "react";
import NextLink from "next/link";
import PropTypes from "prop-types";
import Picture from "components/picture";
import CustomLink from "components/link";
import CoreContainer from "components/core-container";

const chunk = (array) => {
  const list = [];
  if (array?.length > 0) {
    let images = [...array];
    // resize the images to 80 elements
    while (images.length < 80) {
      images = [...images, ...images];
    }
    images = images.slice(0, 80);

    // create chunks
    let i = 0,
      size = 2;
    while (i + size <= images.length) {
      list.push(images.slice(i, i + size));
      i += size;
      size = size === 5 ? 2 : size + 1;
    }
  }
  return list;
};

function renderPicture(picture, { w = 1, h = 1, priority = false, lazyRoot = null } = {}) {
  return (
    <Picture
      lazyRoot={lazyRoot}
      priority={priority}
      key={picture?._id}
      picture={picture}
      layout="intrinsic"
      objectFit="cover"
      width={200 * w}
      height={160 * h}
      className="rounded"
    />
  );
}

function renderChunk(chunk, priority, lazyRoot) {
  if (!chunk?.length) return null;

  if (chunk.length === 2) {
    return (
      <div className="grid w-[200px] grid-cols-1 grid-rows-2 gap-4">
        {renderPicture(chunk[0], { h: 1.5, priority, lazyRoot })}
        {renderPicture(chunk[1], { priority, lazyRoot })}
      </div>
    );
  }
  if (chunk.length === 3) {
    return (
      <div className="grid w-[400px] grid-rows-[1fr_2fr] gap-4">
        <div className="grid grid-cols-2 gap-4">
          {renderPicture(chunk[0], { h: 1.1, priority, lazyRoot })}
          {renderPicture(chunk[1], { h: 1.1, priority, lazyRoot })}
        </div>
        {renderPicture(chunk[2], { priority, lazyRoot })}
      </div>
    );
  }
  if (chunk.length === 4) {
    return (
      <div className="grid w-[400px] grid-rows-[1fr_2fr] gap-4">
        {renderPicture(chunk[0], { w: 2, priority, lazyRoot })}
        <div className="grid grid-cols-2 gap-4">
          <div className="grid grid-rows-2 gap-4">
            {renderPicture(chunk[1], { h: 0.75, priority, lazyRoot })}
            {renderPicture(chunk[2], { h: 0.75, priority, lazyRoot })}
          </div>
          {renderPicture(chunk[3], { h: 0.75, priority, lazyRoot })}
        </div>
      </div>
    );
  }
  if (chunk.length === 5) {
    return (
      <div className="grid w-[400px] grid-rows-3 gap-4">
        <div className="grid grid-cols-2 gap-4">
          {renderPicture(chunk[0], { priority, lazyRoot })}
          {renderPicture(chunk[1], { priority, lazyRoot })}
        </div>
        {renderPicture(chunk[2], { w: 2.1, priority, lazyRoot })}
        <div className="grid grid-cols-2 gap-4">
          {renderPicture(chunk[3], { priority, lazyRoot })}
          {renderPicture(chunk[4], { priority, lazyRoot })}
        </div>
      </div>
    );
  }
  return null;
}

const HorizontalMasonry = ({ data }) => {
  const lazyRoot = useRef(null);
  const { title, subTitle, link, pictures, theme } = data;
  const [priority, setPriority] = useState(false);
  const [chunks, setChunks] = useState([]);

  useEffect(() => {
    setChunks(chunk(pictures));

    // load the remaining images on priority
    const timeoutId = setTimeout(() => {
      setPriority(true);
    }, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pictures]);

  if (!chunks.length) return null;

  return (
    <CoreContainer mRef={lazyRoot} isFullWidth className="py-20" theme={theme?.value}>
      {title && <div className="mb-4 px-20 text-center text-4xl font-normal text-accent-900 lg:text-5xl m-auto max-w-[780px]">{title}</div>}
      {subTitle && <p className="mt-2 text-center text-lg text-neutral-800">{subTitle}</p>}
      {link && (
        <div className="mb-2 mt-6 text-center">
          <CustomLink {...link} />
        </div>
      )}
      <NextLink href="/gallery">
        <div className="relative mt-10 h-[500px] overflow-hidden lg:cursor-pointer">
          <div className="absolute top-0 left-0 flex animate-horizontal-masonry-move overflow-hidden whitespace-nowrap">
            {chunks.map((chunk, i) => (
              <div key={i} className="mr-4">
                {renderChunk(chunk, i < 5 || priority, lazyRoot)}
              </div>
            ))}
          </div>
        </div>
      </NextLink>
    </CoreContainer>
  );
};

HorizontalMasonry.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    subTitle: PropTypes.string,
    pictures: PropTypes.arrayOf(
      PropTypes.shape({
        _id: PropTypes.string,
        image: PropTypes.shape({}),
        project: PropTypes.shape({
          _id: PropTypes.string,
        }),
      })
    ),
    link: PropTypes.shape({
      shouldScroll: PropTypes.bool,
      newWindow: PropTypes.bool,
      isInternal: PropTypes.bool,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  }),
};

export default HorizontalMasonry;
